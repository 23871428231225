import 'slick-carousel';

export default class TopSellSlider {
    constructor() {
        this.slickWrapper = '.top-sell-slider';
    }

    init() {
        if ($(this.slickWrapper).length > 0) {
            $(this.slickWrapper).slick({
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 915,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 481,
                        settings: {
                            slidesToShow: 1.1,
                            slidesToScroll: 1,
                        },
                    },
                ],
                appendArrows: $(this.slickWrapper).find('.slider-nav'),
                prevArrow: '<div class="slick-prev btn btn-white med"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="shape"><path id="Path" d="M20 11.4465C19.8419 11.3135 19.1456 11.4765 18.9731 11.4765C18.0345 11.4765 17.0969 11.5475 16.1624 11.5905C12.2354 11.7725 8.1986 11.9325 4.27781 11.9325" stroke="#E3001B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path id="Path_2" d="M8.74356 19C7.76578 17.8117 7.10083 16.3409 6.2786 15.0512C5.60766 13.9965 4.59399 12.9331 4.03758 11.8841C3.95125 11.7214 4.89484 10.5541 5.01194 10.278C5.51877 9.08568 6.59313 7.8483 7.39997 6.83554C7.87433 6.23979 8.12561 5.46371 8.61792 5" stroke="#E3001B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg></div>',
                nextArrow: '<div class="slick-next btn btn-white med"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="shape"><path id="Path" d="M4 12.5535C4.15815 12.6865 4.8544 12.5235 5.02692 12.5235C5.96553 12.5235 6.90311 12.4525 7.83761 12.4095C11.7646 12.2275 15.8014 12.0675 19.7222 12.0675" stroke="#E3001B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path id="Path_2" d="M15.2564 5C16.2342 6.18827 16.8992 7.65911 17.7214 8.94882C18.3923 10.0035 19.406 11.0669 19.9624 12.1159C20.0487 12.2786 19.1052 13.4459 18.9881 13.722C18.4812 14.9143 17.4069 16.1517 16.6 17.1645C16.1257 17.7602 15.8744 18.5363 15.3821 19" stroke="#E3001B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg></div>',
            })
        }
        this.resizeHandler();
        this.progressBar();
    }

    resizeHandler() {
        // resize
        $(window).on('resize', () => {
            if ($(window).width() <= 1024) {
                if (!$(this.slickWrapper).hasClass('slick-initialized')) {
                    $(this.slickWrapper).slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        mobileFirst: true,
                        infinite: false,
                        dots: false,
                        prevArrow: false,
                        nextArrow: false,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 1,
                                },
                            },
                            {
                                breakpoint: 915,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 1,
                                },
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                },
                            },
                            {
                                breakpoint: 481,
                                settings: {
                                    slidesToShow: 1.1,
                                    slidesToScroll: 1,
                                },
                            },
                        ],
                    });
                }
            }
        });
    }

    progressBar() {
        const $progress = $('.top-sell-progress');

        $(this.slickWrapper).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            let calc;
            if ($(window).width() > 1024) {
                calc = ((nextSlide) / (slick.slideCount - 4)) * 100;
            } else if ($(window).width() > 915) {
                calc = ((nextSlide) / (slick.slideCount - 3)) * 100;
            } else if ($(window).width() > 600) {
                calc = ((nextSlide) / (slick.slideCount - 2)) * 100;
            } else {
                calc = ((nextSlide) / (slick.slideCount - 1)) * 100;
            }

            if (calc == 0) {
                calc = 4;
            }

            $progress
                .css('width', calc + '%')
                .attr('aria-valuenow', calc);
        });
    }
}