export default class AddToCart {

  constructor() {
    this.addToCartBtn = '.custom-add';
  }

  init() {
    $(document).on('click', this.addToCartBtn, event => {
      if (event.target.getAttribute('name') === 'add-to-cart') {
        this.addToCart(event);
      } else {
        this.clickButton(event);
      }
    });
  }

  // AJAX add product to CART with our custom method (see setup.php)
  // for add to cart buttons in blocks
  addToCart(event) {
    event.preventDefault();

    let elem = $(event.currentTarget);
    let product_id = elem.val();
    let qty = 1;

    let data = {
      action: 'add_item_to_cart', product_id: product_id, quantity: qty,
    }
    $(document.body).trigger('adding_to_cart', [elem, data]);

    $.ajax({
      type: 'POST', /* eslint-disable */
      url: woocommerce_params.ajax_url, /* eslint-enable */
      data: data, beforeSend: () => {
        elem.addClass('loading');
      }, success: response => {
        if (response) {
          $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, elem]);
        }
      }, complete: response => {
        elem.removeClass('loading');

        $(document.body).trigger('wc_fragment_refresh');

        var fragments = response.fragments;
        if (fragments) {
          jQuery.each(fragments, function (key, value) {
            jQuery(key).replaceWith(value);
          });
        }
      },
    });
  }

  // out of stock or options needed, do click and redirect
  clickButton(event) {
    window.location.href = event.target.getAttribute('href');
  }
}
