export default function StickyBtn () {
  if($(window).width() <= 768) {

    $(window).scroll(function () {
      const el = $('.woosb-products');
      if(el.length > 0) {
        if (isScrolledIntoView(el)) {
          $('.sticky-btn').hide();
        } else {
          $('.sticky-btn').show();
        }
      }
  })

  }
}

function isScrolledIntoView(elem)
{
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + $(window).height();

    const elemTop = $(elem).offset().top;
    const elemBottom = elemTop + $(elem).height();

    return ((elemBottom - 60 >= docViewTop) && (elemTop + 60 <= docViewBottom));
}
