export default function accordion() {
  'use strict';

  // Accordion Block
  $(document).on('click', '.accordion-heading', (event) => {
    let element = event.currentTarget;
    $(element).parent('.accordion-item').toggleClass('expanded').children('.accordion-content').finish().slideToggle();
    $('.accordion-item').not($(element).parent('.accordion-item')).removeClass('expanded').children('.accordion-content').finish().slideUp();
  });

}