export default function gridBuilder () {

  $('body').on('click', '.wpgb-facet-title', function() {
    if( $(this).next().is(':visible')){
      $(this).next().slideUp('slow');
      $(this).next().parent().addClass('closed');
    } else {
      $(this).next().parent().removeClass('closed');
      $(this).next().slideDown('slow');
    }
  });

  $(window).on('load resize', function () {
    setTimeout(() => {
      const height = $('.store-locator-grid .wpgb-sidebar-right').height() - 178;
      $('.store-locator-grid .wpgb-viewport').height(height);
    }, 200);
  });

}