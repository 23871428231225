export default class ProductVariation {

  constructor () {
    this.variationRadioButton = '.product-offer .variation-swatch-input[name="pa_colors"]';
    this.variationDropdown = '.product-offer .variations-dropdowns select';
    this.bundleVariationDropdown = '.product-offer .variations .variation select';
  }

	init () {
    this.colorStock();
    this.bundleVariationPricePosition();
    this.variationOutOfStock();
    this.variationClickHandler();
    this.variationChangeHandler();
    // this.bundleVariationChangeHandler();
    this.bundleVariationImage();
  }

  colorStock() {
    $( window ).on( 'load', () =>  {
      // Get the selected dropdown
      const dropdown = $('.variations_form .variations-dropdowns [name^="attribute_pa_"]').not('[id="pa_colors"]');
      const hiddenColorsDropdown = $('.variations_form .variations-dropdowns [id="pa_colors"]');

      const selected = $(dropdown).find('option:selected').val();
      const selectedColor = $(hiddenColorsDropdown).find('option:selected').val();

      $(this.variationRadioButton).each((index, element) => {
        if(($(element).val() == selectedColor)) {
          $(element).next().addClass('selected');
        }
      });

      if($( '.variations_form' ).attr('data-product_variations') !== undefined) {
        let form = JSON.parse($( '.variations_form' ).attr('data-product_variations'));

        // If color attribute
        if( $(this.variationRadioButton) ) {
          //Get all options (e.g. 16cm BLUE, 18cm BLUE, 16cm GREEN, 18cm GREEN)
          form.forEach(item => {
            // If the only attribute is color
            if(Object.keys(item.attributes)[0] == 'attribute_pa_colors' && Object.keys(item.attributes).length < 2) {
              // If options is not available
              if (!item.is_in_stock) {
                  // Loop through all the colors and add class to the unavailale
                $(this.variationRadioButton).each((index, element) => {
                  if(($(element).val() == item.attributes.attribute_pa_colors)) {
                    $(element).next().addClass('not-available');
                  }
                });
              }
            }
            // Else if attributes are color + dropdown
            else {
              // Get all options with default variation (e.g. 16cm BLUE, 16cm GREEN, 16cm RED)
              if (Object.values(item.attributes).includes(selected)) {
                // If options is not available
                if (!item.is_in_stock) {
                  // Loop through the colors and add class to this unavailable option color
                  $(this.variationRadioButton).each((index, element) => {
                    if(($(element).val() == item.attributes.attribute_pa_colors)) {
                      $(element).next().addClass('not-available');
                    }
                  });
                }
              }
            }
          })
        }
      }
    });
  }

  variationClickHandler() {
    // Get the click on color circle
    $( document ).on( 'change', this.variationRadioButton, event => {
      let element = event.currentTarget;
      // Get option slug and taxonomy
      let selectedOption = $(element).val();              /* green, blue etc.*/
      let variationTaxonomy = $(element).attr('name');    /* color, size, etc.*/

      $(element).next('label').addClass('selected');
      $(this.variationRadioButton).not($(element)).next('label').removeClass('selected');
      // Change option in hidden form
      $(`.variations_form .variations-dropdowns [id^="${variationTaxonomy}"]`).val(selectedOption).trigger('change');
    });

    // Get the click on dropdowns (e.g. sizes - 16cm, 21cm)
    $( document ).on( 'change', this.variationDropdown, event => {
      let element = event.currentTarget;
      // Get option slug and taxonomy
      let selectedOption = $(element).val();
      let variationTaxonomy = $(element).attr('id');
      // Change option in hidden form
      $(`.variations_form .variations-dropdowns [name^="${variationTaxonomy}"]`).val(selectedOption).trigger('change');

      let form = JSON.parse($( '.variations_form' ).attr('data-product_variations'));
        // If clicked variation is not color (e.g. size 16cm)
        if($(element).attr('id') !== 'pa_colors') {
          //  Reset all colors availability
          $(this.variationRadioButton).each((index, element) => {
            $(element).next().removeClass('not-available');
          });
          //Get all options (e.g. 16cm BLUE, 18cm BLUE, 16cm GREEN, 18cm GREEN)
          form.forEach(item => {
            // Get all options with this variation (e.g. 16cm BLUE, 16cm GREEN, 16cm RED)
              if (Object.values(item.attributes).includes(selectedOption)) {
                // If options is not available
                if (!item.is_in_stock) {
                  // Loop through the colors and add class to this unavailable option color
                  $(this.variationRadioButton).each((index, element) => {
                    if(($(element).val() == item.attributes.attribute_pa_colors)) {
                      $(element).next().addClass('not-available');
                    }
                  });
                }
              }
            })
        }
    })

    $( document ).on( 'change', this.bundleVariationDropdown, event => {
      let element = event.currentTarget;
      // Get option slug and taxonomy
      let selectedOption = $(element).val();
      let variationTaxonomy = $(element).attr('id');
      // Change option in hidden form
      $(`.variations_form .variation [name^="${variationTaxonomy}"]`).val(selectedOption).trigger('change');
    })
  }

  variationChangeHandler() {
    $( document ).on( 'show_variation', '.single_variation_wrap', ( event, variation ) => {
      if(variation.price_html != '') {
        this.updatePrice(variation.price_html);
      }
    });
  }

  // used when price calculation is automatic from the bundled products
  // bundleVariationChangeHandler() {
  //   $(document).on('woosb_calc_price', (event, total_price, price, total_html) => {
  //     this.updatePrice(total_html);
  //   })
  // }

  updatePrice(price) {
    $('.product-price .custom-price').html(price);
  }

  bundleVariationImage() {
    $(document).on('found_variation', function(e, t) {
      const $product = $(e['target']).closest('.woosb-product');

      if ($product.length) {

        $product.find('.woosb-thumb-ori').show();
        $product.find('.woosb-thumb-new').html('').hide();

        $(document).trigger('woosb_found_variation', [$product, t]);
      }
    });
  }

  bundleVariationPricePosition() {
    $('.woosb-product .woosb-price').each( function() {
      const title = $(this).prev().find('.woosb-name');
      $(this).detach().insertAfter(title);
    });
  }

  variationOutOfStock() {
    if( $('.product').hasClass('outofstock')) {
      $('.product-offer').find('select').attr('disabled', 'disabled');
    }
  }
}
