export default function changeTabs() {
  $('.tab').each((index, element) => {

    $(element).on('click', function () {
      const anchor = $(this).find('a');
      const id = $(anchor).attr('id');
      $('.tab').not(this).removeClass('is-active');
      $(this).addClass('is-active');

      $('.tab-1 form, .tab-2 form').removeClass('open');
      $(`.${id} form`).addClass('open');
    })
  });
}