export default function mobileFiltersBtn () {
  
  $('#filter-btn').detach().insertAfter('.wpgb-facet-8');

  $('.wpgb-sidebar-left .filter').on('click', function(){
    if($(window).width() <= 768) {
      $('.modal').slideDown();
    }
  });

  $('.modal .close, .modal .apply').on('click', function() {
    $('.modal').slideUp();
  })
}