import OffersSlider from '../modules/OffersSlider';
import NewProductsSlider from '../modules/NewProductsSlider';
import CrossSellingSlider from '../modules/CrossSellingSlider';
import countrySwitcher from '../modules/country-switcher';
import gridBuilder from '../modules/grid-builder';
import GallerySlider from '../modules/product/GallerySlider';
import QuantityAction from '../modules/product/quantity-action';
import ProductVariation from '../modules/product/ProductVariation';
import mobileFiltersBtn from '../modules/filters-mobile';
import StickyBtn from '../modules/product/StickyBtn';
import smoothScroll from '../modules/smooth-scroll';
import searchBar from '../modules/components/search-bar';
import Header from '../modules/Header';
import cart from '../modules/cart';
import accordion from '../modules/components/accordion';
import checkout from '../modules/checkout';
import eshopsList from '../modules/components/eshops-list';
import changeTabs from '../modules/login-register-tabs';
import AddToCart from '../modules/product/AddToCart';
import langSwitcher from '../modules/lang-switcher';
import TopSellSlider from '../modules/TopSellSlider';
import TopInfoBanner from '../modules/components/top-info-banner';

export default {
  init() {
    // JavaScript to be fired on all pages
    new TopInfoBanner().init();
    new NewProductsSlider().init();
    new OffersSlider().init();
    new CrossSellingSlider().init();
    new TopSellSlider().init();
    new GallerySlider().init();
    new QuantityAction().init();
    new ProductVariation().init();
    new Header().init();
    new AddToCart().init();
    searchBar();
    countrySwitcher();
    langSwitcher();
    gridBuilder();
    mobileFiltersBtn();
    StickyBtn();
    smoothScroll();
    cart();
    accordion();
    checkout();
    eshopsList();
    changeTabs();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    $(function () {
      const pathName = document.location.pathname;
      window.onbeforeunload = function () {
          let scrollPosition = $(document).scrollTop();
          sessionStorage.setItem('scrollPosition_' + pathName, scrollPosition.toString());
      }
      if (sessionStorage['scrollPosition_' + pathName]) {
          $(document).scrollTop(sessionStorage.getItem('scrollPosition_' + pathName));
      }
      if($('body').hasClass('single-product') && $('body').find('.instock.purchasable')){
        $('body').addClass('purchasable');
      }
    });
  },
};
