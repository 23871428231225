export default function searchBar() {
  $('.icons-nav .search').on('click', function() {
    if($(window).width() >= 768) {
      $('.header .content').children().not('.brand, .search-bar').hide();
    } else {
      $('.header .content').children().not('.search-bar').hide();
    }
    $('.search-bar').removeClass('closed');
  })

  $('.search-bar .close').on('click', function() {
    if($(window).width() >= 768) {
      $('.header .content').children().not('.search-bar, .mobile-menu').show();
    } else {
      $('.header .content').children().not('.search-bar, nav').show();
    }
    $('.search-bar').addClass('closed');
  })
}