export default function eshopsList() {
  'use strict';

  $('.eshops-list .item').slice(0, 8).css({'display': 'flex'});
  $('.view-more .btn').on('click', function(e){
    e.preventDefault();
    let element = e.currentTarget;
    $(element).hide();
    $('.eshops-list .item:hidden').slice(0).css({'display': 'flex'});
  });

}