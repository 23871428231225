export default function checkout() {
  $(window).on('load', function () {
    setTimeout(function () {
      $('#payment #place_order').attr('disabled', 'disabled');
    }, 1550);
  });

  $(document).on('change', '#terms', function () {
    let ischecked = $(this).is(':checked');
    if (!ischecked) {
      $('#payment #place_order').attr('disabled', 'disabled');
    } else {
      $('#payment #place_order').removeAttr('disabled');
    }
  });

  $(document).on('change', '#createaccount-checkbox', function () {
    $('label#createaccount-label').toggle();
  });
}
