export default class TopInfoBanner {
    constructor() {
        this.modal = document.querySelector('#top-info-modal');
        this.modalOverlay = document.querySelector('.top-info-modal-overlay');
        this.btnCloseModal = document.querySelector('.close-modal__btn');
        this.topBanner = document.querySelector('.top-info-banner');
        this.maxTabletWith = 992;
        this.currentTextsIndex = 0;
        this.NewsletterBtn = document.querySelector('#top-info-modal .card.card-newsletter .btn');
        this.NewsletterFooter = document.querySelector('.newsletter-banner');
    }

    init() {
        if (this.topBanner === null) {
            return;
        }

        setInterval(() => {
            this.toggleTextVisibility()
        }, 4500);

        this.topBanner.addEventListener('click', () => {
            this.openModal()
        });
        this.modalOverlay.addEventListener('click', () => {
            this.closeModal()
        });
        this.btnCloseModal.addEventListener('click', () => {
            this.closeModal();
        });

        // Close the modal and scroll to newsletter block;
        if ( this.NewsletterFooter ) {
            this.NewsletterBtn.addEventListener('click', () => {
                $('.close-modal__btn').click();
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.newsletter-banner').offset().top - 100,
                }, 1000);
            });
        }

        this.handleOverflowWithMenu();
    }

    toggleTextVisibility() {
        const delay = 1000; // Délai en millisecondes entre chaque texte
        const texts = document.querySelectorAll('.top-info-banner__text span');

        texts[this.currentTextsIndex].classList.remove('visible');
        // set next index
        this.currentTextsIndex = (this.currentTextsIndex + 1) % texts.length;

        setTimeout(() => {
            texts[this.currentTextsIndex].classList.add('visible');
        }, delay);
    }

    openModal() {
        this.modal.style.display = 'block';
        this.modalOverlay.style.display = 'block';

        if (window.innerWidth < this.maxTabletWith) {
            document.querySelector('body').style.overflowY = 'hidden';
            this.modal.style.overflowY = 'auto';
        }
    }

    closeModal() {
        this.modal.setAttribute('closing', '');
        this.modalOverlay.setAttribute('closing', '');

        this.modal.addEventListener('animationend', () => {
            this.modalOverlay.removeAttribute('closing');
            this.modalOverlay.style.display = 'none';
        }, {once: true})

        this.modal.addEventListener('animationend', () => {
            this.modal.removeAttribute('closing');
            this.modal.style.display = 'none';
        }, {once: true});

        if (window.innerWidth < this.maxTabletWith) {
            document.querySelector('body').style.overflowY = 'hidden';
            this.modal.style.height = 'auto';
            this.modal.style.overflowY = 'none';
        }
    }

    handleOverflowWithMenu() {
        let navigation = document.querySelector('header nav');
        if (navigation === null) {
            return;
        }

        navigation.addEventListener('mouseover', () => {
            this.topBanner.classList.add('menu-over');
        });
        navigation.addEventListener('mouseleave', () => {
            this.topBanner.classList.remove('menu-over');
        });
    }
}