export default class Header {
	constructor () {
    this.headerElement = '.header';
    this.megaMenuItem = '.main-menu-dropdown-wrapper > ul > li';
    this.megaMenuItemAnchor = '.main-menu-dropdown-wrapper > ul > li > a';
	}

	init () {
    this.openMenuOnHover();
    this.megaMenuSubMenuPositionHandler();
    this.burgerToggle();
    this.mobileToggleDropdown();
  }
  
  openMenuOnHover() {
    // Hover open mega menu
    let hoverTimeout;
    // prevent triggering mouse events on mobile
    $('.open-mega-menu').on('mouseenter', event => {
      if($(window).width() > 915){
        let element = $(event.currentTarget);
        clearTimeout(hoverTimeout);
        element.addClass('hover').siblings('.open-mega-menu').removeClass('hover');
        $('.page-overlay').addClass('active-menu');
      }
    });

    $('.open-mega-menu').on('mouseleave', event => {
      if($(window).width() > 915){
        let element = $(event.currentTarget);
        hoverTimeout = setTimeout(function() {
          element.removeClass('hover');
          $('.page-overlay').removeClass('active-menu');
        }, 300);
      }
    });
  }

  megaMenuSubMenuPositionHandler() {
    $(this.megaMenuItemAnchor).on('mouseenter', event => {
      if($(window).width() > 915){
        let element = event.currentTarget;
        $(element).addClass('opened');
        let submenu = $(element).siblings('.sub-menu');
        let image = $(element).siblings('.main-menu-widget');

        $(this.megaMenuItem).children('.sub-menu').not(submenu).hide();
        $(this.megaMenuItem).children('.main-menu-widget').not(image).hide();
        $(this.megaMenuItemAnchor).not(element).removeClass('opened');

        $(submenu).show();
        $(image).show();
      }
    })
  }

  burgerToggle() {
    $('.mobile-menu').click( event => {
      let element = $(event.currentTarget);
      $(this.megaMenuItemAnchor).removeClass('opened');
      if(element.attr('aria-expanded') == 'false'){
        $(element).attr('aria-expanded', true)
        $(element).attr('src', '/wp-content/themes/maped/resources/assets/images/1-icons/24/close.svg');
        $('.nav-primary').addClass('mobile-menu-expanded');
        $('body').addClass('disable-scroll');
      }else {
        $(element).attr('aria-expanded', false)
        $(element).attr('src', '/wp-content/themes/maped/resources/assets/images/1-icons/24/menu.svg');
        $('.nav-primary').removeClass('mobile-menu-expanded');
        $('body').removeClass('disable-scroll');
      }
    })
  }

  mobileToggleDropdown() {
    $('.nav-primary .menu-item-has-children').each( function() {
      $(this).on('click', event => {
        let element = $(event.currentTarget);
        // prevent default only if target is li element
        if(event.target == event.currentTarget || $(event.target).attr('href') === undefined) {
          event.stopPropagation();
          element.toggleClass('menu-item-expanded').children('.main-menu-dropdown, .sub-menu').toggleClass('expanded');
        }
      })
    });
  }
}