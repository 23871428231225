import 'slick-carousel';

export default class gallerySlider {
    constructor() {
        this.slickWrapper = '.product-slides';
    }

    init() {
        this.initSlick();
    }

    initSlick() {
        if ($(this.slickWrapper).length) {
            $(this.slickWrapper).each((index, element) => {
                if ($(element).children('.img-box').length > 0) {
                    $(element).slick({
                        slidesToShow: 1,
                        infinite: false,
                        adaptiveHeight: true,
                        dots: true,
                        draggable: true,
                        arrows: false,
                        customPaging: function (slider, i) {
                            let html_attributes = '';
                            let attributes = $(slider.$slides[i]).find('.img-box img').data('product_attributes');
                            if (attributes) {
                                Object.entries(attributes).map(([k, v]) => {
                                    html_attributes += ` ${k}="${v}"`;
                                });
                            }
                            return `<div class="thumb"><img title="${$(slider.$slides[i]).find('.img-box img').attr('title')}" src="${$(slider.$slides[i]).find('.img-box img').data('thumb')}"${html_attributes}></div>`;
                        },
                        responsive: [
                            {
                                breakpoint: 768,
                                settings: {
                                    draggable: true,
                                    touchMove: true,
                                },
                            },
                        ],
                    });
                }
            })
        }

        this.resizeHandler();
    }

    resizeHandler() {
        // resize
        $(window).on('resize', () => {
            if ($(window).width() <= 768) {
                if (!$(this.slickWrapper).hasClass('slick-initialized')) {
                    $(this.slickWrapper).slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        mobileFirst: true,
                    });
                }

                this.progressBar();
            }
        });
    }

    progressBar() {
        const $progress = $('.product-gallery-progress');

        $(this.slickWrapper).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            let calc = ((nextSlide) / (slick.slideCount - 1)) * 100;

            if (calc == 0) {
                calc = 10;
            }

            $progress
                .css('width', calc + '%')
                .attr('aria-valuenow', calc);
        });
    }
}