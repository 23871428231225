export default function cart () {
  const bundledProducts = $('.woosb-cart-child');
  const bundleProduct = $('.woosb-cart-parent');
  const divider = '<hr/>';

  bundledProducts.each((index, element) => {

    if(!($(element).next().hasClass('woosb-cart-child'))) {
        $(element).css({'border-radius': '0px 0px 24px 24px'});
    }
  });

  $(bundleProduct).append(divider);
}