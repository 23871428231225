export default class QuantityAction {
	constructor () {
        this.btnIncrement = '.quantity-btn-up';
        this.btnDecrement = '.quantity-btn-down';
	}

	init () {
        $(document).on('click', this.btnIncrement, this.handleIncrement);
        $(document).on('click', this.btnDecrement, this.handleDecrement);
    }

    handleIncrement (event) {
        let element = event.currentTarget;
        const input = $(element).parent().parent().find('.qty');
        const max = input.attr('max');
        let count = Number(input.val());
        if(max && count >= max) return false;
        input.val(count += 1);
        input.trigger('change');
    }

    handleDecrement (event) {
        let element = event.currentTarget;
        const input = $(element).parent().parent().find('.qty');
        const min = input.attr('min');
        // const is_in_cart = $(element).parents(this.cartContainer) == 0 ? true : false;
        let count = Number(input.val());
        if(count == 0 || (min && count == min)) return false;   /** (count <= 1 && is_in_cart) ||  */
        input.val(count -= 1);
        input.trigger('change');
    }
}