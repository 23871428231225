export default function countrySwitcher () {
  $('.country-switch .menu-item-has-children').on('click', function (e) {
    e.stopPropagation();
    if($(this).find('.sub-menu').hasClass('show')) {
      $(this).find('.sub-menu').removeClass('show');
      $(this).removeClass('expanded');
    } else {
      $(this).find('.sub-menu').addClass('show');
      $(this).addClass('expanded');
    }
  });

  $(document).click(function(event){
    if(event.currentTarget !== $('.country-switch')) {
      $('.country-switch').find('.sub-menu').removeClass('show');
      $('.country-switch .menu-item-has-children').removeClass('expanded');
    }
  });
}
