import 'slick-carousel';

export default class OffersSlider {
	constructor () {
    this.slickWrapper = '.offers-slider';
	}

	init () {
    if($(this.slickWrapper).length > 0){
      $(this.slickWrapper).slick({
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        mobileFirst: true,
        prevArrow: false,
        nextArrow: false,
        responsive: [
          {
            breakpoint: 901,
            settings: 'unslick',
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2.1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1.1,
              slidesToScroll: 1,
            },
          },
        ],
      })
    }
    this.resizeHandler();
    this.progressBar();
  }

  resizeHandler() {
    // resize
    $(window).on('resize', () => {
      if( $(window).width() <= 1024) {
          if( !$(this.slickWrapper).hasClass('slick-initialized')){
            $(this.slickWrapper).slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              mobileFirst: true,
              infinite: false,
              dots: false,
              prevArrow: false,
              nextArrow: false,
              responsive: [
                {
                  breakpoint: 1025,
                  settings: 'unslick',
                },
                {
                  breakpoint: 769,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 481,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          }
      }
    });
  }

  progressBar() {
    const $progress = $('.offers-progress');

    $(this.slickWrapper).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      let calc = ( (nextSlide) / (slick.slideCount - 1) ) * 100;
      
      if( $(window).width() > 769 ) {
        calc = ( Math.ceil ( (nextSlide) / (slick.slideCount / 2) ) ) * 100;
      }

      if(calc == 0) {
        calc = 10;
      }

      $progress
        .css('width', calc + '%')
        .attr('aria-valuenow', calc );
    });
  }
}